import { AxiosResponse } from 'axios';
import { StorefrontClient } from '../../clients';

export const getBadges = async (getBadgesRequest: GetBadgesRequest): Promise<AxiosResponse<GetBadgesResponse>> =>
  StorefrontClient.get<GetBadgesResponse>('/badges', { params: getBadgesRequest }).catch((err: RequestError) =>
    Promise.reject(err.response.data.message),
  );

export const getBadgesByExternalProductId = async (
  getBadgesRequest: GetBadgesRequest,
): Promise<AxiosResponse<Badge[]>> =>
  StorefrontClient.get<Badge[]>('/badges', { params: getBadgesRequest }).catch((err: RequestError) =>
    Promise.reject(err.response.data.message),
  );

export const getBadge = async (badgeId: string): Promise<AxiosResponse<Badge>> =>
  StorefrontClient.get<Badge>(`/badges/${badgeId}`).catch((err: RequestError) =>
    Promise.reject(err.response.data.message),
  );

export const getSupportersByExternalProductId = async (
  getSupporterRequest: GetSupporterRequest,
): Promise<AxiosResponse<Supporter[]>> =>
  StorefrontClient.get<Supporter[]>(`/petitions/${getSupporterRequest.externalProductId}/supporters`, {
    params: getSupporterRequest,
  }).catch((err: RequestError) => Promise.reject(err.response.data.message));
