import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { S1 } from '../Typography';

const EmptyRowWrapper = styled.div`
  padding: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  border-bottom: 1px solid ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  height: 52px;
`;

const EmptyTextWrapper = styled.div`
  margin-left: 16px;
`;

const EmptyText = styled(S1)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
`;

const EmptyImage = styled.div`
  margin: 4px 16px 4px 4px;
`;

type EmptyRowProps = {
  hideImage?: boolean;
};

const EmptyRow: React.FC<EmptyRowProps> = ({ hideImage }: EmptyRowProps) => (
  <EmptyRowWrapper>
    <EmptyTextWrapper>
      <EmptyText>Your username here</EmptyText>
    </EmptyTextWrapper>
    {hideImage ? (
      <></>
    ) : (
      <EmptyImage>
        <Image src="/assets/badges/unclaimed-badge.png" width={52} height={52} quality={90} />
      </EmptyImage>
    )}
  </EmptyRowWrapper>
);

export default EmptyRow;
