import { hasProductTag } from './product';

export const parseForShopifyDomain = (storeURL: string) => {
  const splitURL = storeURL.split('https://');
  if (splitURL.length < 2) return storeURL;
  return splitURL[1];
};

// Reorders products in array so that petitions are displayed last
export const reorderProducts = (products: Shopify.ProductEdge[]) => {
  const productsCopy = products.slice();
  const petitions = productsCopy.filter((product) => hasProductTag(product.node.tags, 'petition'));
  const notPetitions = productsCopy.filter((product) => !hasProductTag(product.node.tags, 'petition'));
  return [...notPetitions, ...petitions];
};
