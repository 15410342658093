import React from 'react';
import styled from 'styled-components';
import ReactCountdown, { CountdownRenderProps } from 'react-countdown';
import { H5 } from '../Typography';

const CountdownTimerWrapper = styled.span<{
  isLastDay: boolean;
  customColor?: string;
}>`
  ${H5} {
    color: ${({ theme, isLastDay, customColor }) =>
      customColor || (isLastDay ? theme.colors.error : theme.colors.neutral7)};
  }
`;

type CountdownTimerProps = {
  date: string;
  removeStyling?: boolean;
  customColor?: string;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({ date, removeStyling, customColor }: CountdownTimerProps) => {
  const renderer = ({ formatted: { days, hours, minutes, seconds } }: CountdownRenderProps) => (
    <CountdownTimerWrapper isLastDay={days === '00'} customColor={customColor}>
      {removeStyling ? (
        <div>
          {days}d&nbsp;:&nbsp;{hours}h&nbsp;:&nbsp;{minutes}m&nbsp;:&nbsp;{seconds}s
        </div>
      ) : (
        <H5>
          {days}d&nbsp;&nbsp;:&nbsp;&nbsp;{hours}h&nbsp;&nbsp;:&nbsp;&nbsp;{minutes}m&nbsp;&nbsp;:&nbsp;&nbsp;{seconds}s
        </H5>
      )}
    </CountdownTimerWrapper>
  );

  return <ReactCountdown date={new Date(date)} renderer={renderer} />;
};

export default CountdownTimer;
