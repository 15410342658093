import React from 'react';
import styled from 'styled-components';
import { Link as StyledLink } from '@makeship/core';
import { S1, P2, H1, H2, H3, H4, H5, H6 } from '../../../Typography';
import { transformHtml } from '../../../../utils/htmr';
import { getProductFromContext } from '../../../../context/product';

const CreatorMessageWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

const CreatorHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CreatorHeader = styled(S1)`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const DescriptionP = styled(P2)`
  padding: 8px 0;
`;

const CreatorMessage: React.FC = () => {
  const product = getProductFromContext();

  return (
    <CreatorMessageWrapper>
      <CreatorHeaderWrapper>
        <CreatorHeader>Message from the Creator</CreatorHeader>
      </CreatorHeaderWrapper>
      {transformHtml(product.descriptionHtml, {
        p: DescriptionP,
        div: DescriptionP,
        a: StyledLink.Primary,
        h1: H1,
        h2: H2,
        h3: H3,
        h4: H4,
        h5: H5,
        h6: H6,
      })}
    </CreatorMessageWrapper>
  );
};

export default CreatorMessage;
