import React from 'react';
import styled from 'styled-components';
import htmr from 'htmr';
import { Link as StyledLink } from '@makeship/core';
import { P2, H1, H2, H3, H4, H5, H6 } from '../components/Typography';

const IFrame = styled.iframe`
  width: 100%;
`;

type TransformType = {
  [x: string]: React.ReactNode;
};

const defaultTransform: TransformType = {
  p: P2,
  a: StyledLink.Primary,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  iframe: IFrame,
};

export const transformHtml = (html: string, transform: TransformType = defaultTransform): React.ReactNode =>
  htmr(html, { transform });
