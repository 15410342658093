import React, { ChangeEvent, useState } from 'react';
import styled, { css } from 'styled-components';
import Image from 'next/image';
import { utils } from '@makeship/core';
import posthog from 'posthog-js';
import { P2, S2 } from '../../../Typography';
import { sort360ImageArray } from '../../../../utils/product';
import { isUserLoggedIn, sendGAEvent } from '../../../../utils/analytics';
import { useStore } from '../../../../store';

const Track = css`
  box-sizing: border-box;
  border: none;
  height: 32px;
  background: ${({ theme }) => utils.hexToRGBA(theme.colors.sliderBackground, theme.alpha.dark)};
  border-radius: 24px;
`;

const Fill = css`
  height: 32px;
  background: ${({ theme }) => utils.hexToRGBA(theme.colors.sliderBackground, theme.alpha.dark)};
  border-radius: 4px;
`;

const Thumb = css`
  box-sizing: border-box;
  border: none;
  width: 36px;
  height: 24px;
  border-radius: 12px;
  background: transparent;
  background: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 0px 5px rgba(66, 97, 255, 0.5);
  background-image: url('/assets/icons/double-white-arrow.svg');
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const RangeSlider = styled.input`
  width: 100%;
  &,
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  &:hover {
    cursor: pointer;
  }

  margin: 0;
  padding: 0;
  height: 24px;
  background: transparent;
  font: 1em/1 arial, sans-serif;

  &::-moz-range-track {
    ${Track};
  }
  &::-ms-track {
    ${Track};
  }
  &::-moz-range-progress {
    ${Fill};
  }
  &::-ms-fill-lower {
    ${Fill};
  }
  &::-webkit-slider-thumb {
    margin-top: calc(0.5 * (32px - 24px));
    ${Thumb};
  }
  &::-moz-range-thumb {
    ${Thumb};
  }
  &::-ms-thumb {
    margin-top: 0;
    ${Thumb};
  }
  &::-ms-tooltip {
    display: none;
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  background-color: transparent;
  padding: 0 4px;
`;

const DotWrapper = styled.div`
  z-index: -2;
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-image: linear-gradient(
      ${({ theme }) => utils.hexToRGBA(theme.colors.sliderBackground, theme.alpha.dark)},
      ${({ theme }) => utils.hexToRGBA(theme.colors.sliderBackground, theme.alpha.dark)}
    ),
    linear-gradient(
      ${({ theme }) => utils.hexToRGBA(theme.colors.sliderBackground, theme.alpha.dark)},
      ${({ theme }) => utils.hexToRGBA(theme.colors.sliderBackground, theme.alpha.dark)}
    );
`;

const Dot = styled.div`
  z-index: -1;
  pointer-events: none;
  position: relative;
  top: 0px;
  right: 0px;
  width: 6px;
  height: 6px;
  border-radius: 32px;
  background: ${({ theme }) => utils.hexToRGBA(theme.colors.primary, theme.alpha.extraDark)};
`;

const SliderTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

const SliderTextContainer = styled.div`
  position: relative;
  top: -60px;
  z-index: 2;
`;

const ImageWrapper = styled.div<{ isSelected: boolean }>`
  visibility: ${({ isSelected }) => (isSelected ? 'visible;' : 'hidden')};
  height: ${({ isSelected }) => (isSelected ? 'auto;' : '0')};
`;

type Product360ViewerProps = {
  imageArray: Shopify.Image[];
};

const Product360Viewer: React.FC<Product360ViewerProps> = ({ imageArray }: Product360ViewerProps) => {
  const [imageIndex, setCurrentImageIndex] = useState(0);
  const [value, setValue] = useState(0);
  const { state } = useStore();

  const handleValueChange = (value: number) => {
    const newImageIndex = Math.floor((value / 101.0) * imageArray.length);
    setCurrentImageIndex(newImageIndex);
  };

  const sortedImageArray = sort360ImageArray(imageArray);

  return (
    <>
      {sortedImageArray.map((image, index) => (
        <ImageWrapper isSelected={index === imageIndex} key={image.transformedSrc}>
          <Image
            src={image.transformedSrc}
            quality={90}
            height={1000}
            width={1000}
            unoptimized
            alt={image.altText || 'product image'}
          />
        </ImageWrapper>
      ))}
      <SliderTextContainer>
        <SliderWrapper>
          <RangeSlider
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = parseInt(e.target.value, 10);
              handleValueChange(value);
              setValue(value);
              value === 1 && sendGAEvent('360_slider');
              value === 1 && posthog.capture('360_slider', { is_logged_in: isUserLoggedIn(state.user) });
            }}
            type="range"
            value={value}
          />
          <DotWrapper>
            {Array.from({ length: 16 }, (_, index) => (
              <Dot key={index} />
            ))}
          </DotWrapper>
        </SliderWrapper>
        <SliderTextWrapper>
          <S2>360° view</S2>
          <P2>Slide to rotate</P2>
        </SliderTextWrapper>
      </SliderTextContainer>
    </>
  );
};

export default Product360Viewer;
