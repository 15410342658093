import { MutableRefObject, useEffect, useState } from 'react';

export function useIntersectionObserver(
  containerRef: MutableRefObject<Element | null>,
  options: IntersectionObserverInit = {},
) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef?.current);
    }
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef?.current);
      }
    };
  }, [containerRef, options]);

  return [isVisible];
}
